<template>
  <v-card class="grap-card" flat color="surface">
    <div id="chart">
      <div class="ml-5 text-subtitle-2 font-weight-bold">SPO2&nbsp;<span class="font-weight-light">(Percent)</span>
      </div>
      <v-skeleton-loader v-if="isEmpty( lastHRColor )" color="surface" type="image"/>
      <VueApexCharts v-if="isChartVisible && !isEmpty(spo2DynmaicMarkers && lastHRColor )" ref="spO2Chart" type="line" height="150"
        class="mr-8 ml-1" :options="spO2ChartOptions" :series="spO2DataPoints">
      </VueApexCharts>
      <div class="ml-5 text-subtitle-2 font-weight-bold">Perfusion Index&nbsp;<span
          class="font-weight-light">(Percent)</span>
      </div>
      <v-skeleton-loader v-if="isEmpty(lastHRColor)" color="surface" type="image"/>
      <VueApexCharts v-if="isChartVisible && !isEmpty(piDynamicMarkers && lastHRColor)" ref="piChart" type="line" height="150"
        class="mr-8 ml-1" :options="piChartOptions" :series="piDataPoints">
      </VueApexCharts>
      <div class="ml-5 text-subtitle-2 font-weight-bold">HR&nbsp;<span class="font-weight-light">(bpm)</span>
      </div>
      <v-skeleton-loader v-if="isEmpty(lastHRColor )" color="surface" type="image"/>
      <VueApexCharts v-if="isChartVisible && !isEmpty(hrDynamicMarkers && lastHRColor )" ref="hrChart" type="line" height="150px"
        class="mr-8 ml-3" :options="hrChartOptions" :series="hrDataPoints">
      </VueApexCharts>
    </div>
    <v-snackbar color="error" class="text-white mt-16" v-model="showLoadThresholdError" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadThresholdError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import VueApexCharts from "vue3-apexcharts";
import { PropType, ref, watch, onMounted, computed, nextTick, } from 'vue';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { IPatientVitalConfigureResponse, IVitalConfigure, PulseVitalThresholdCriteria, SPO2VitalThresholdCriteria } from '@/interfaces/IVitalConfigure'
import ApexCharts from 'apexcharts';
import { VITAL } from '@/enums/readings';
import { find, findIndex, first, isEmpty, last, sortBy } from "lodash";


const props = defineProps({
  spO2DataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  hrDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  piDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true
  },
})
const isChartVisible = ref(true);
const spO2Chart = ref<ApexCharts | null>(null);
const piChart = ref<ApexCharts | null>(null);
const hrChart = ref<ApexCharts | null>(null)
const showLoadThresholdError = ref(false);
const errorMessage = ref("");
const fetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const pulseFetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const lastSpo2Color = ref<string>('');
const lastPiColor = ref<string>('');
const lastHRColor = ref<string>('');
const spo2DataValue = ref<number[]>([]);
const hrDataValue = ref<number[]>([]);
const piDataValue = ref<number[]>([]);
const spo2ThresholdData = ref({
  critical: 0,
  normal: 0,
  warningMax: 0,
  warningMin: 0,
})
const pulsethreshold = ref({
  criticalMax: 0,
  criticalMin: 0,
  warnMin: 0,
  warnMax: 0,
  normalMin: 0,
  normalMax: 0
})
const emit = defineEmits(['spo2ColorUpdate', 'piColorUpdate', 'hrColorUpdate'])
watch([fetchVitalsConfigurationData, pulseFetchVitalsConfigurationData],

  ([updatedFetchVitalsConfigurationData, updatedPulseFetchVitalsConfigurationData]) => {

    const spo2Thresholds = (updatedFetchVitalsConfigurationData?.config as SPO2VitalThresholdCriteria)?.spO2.thresholds;
    if (spo2Thresholds) {
      spo2ThresholdData.value = spo2Thresholds;
    }
    const pulseThresholds = (updatedPulseFetchVitalsConfigurationData?.config as PulseVitalThresholdCriteria)?.pulse.thresholds;
    if (pulseThresholds) {
      pulsethreshold.value = pulseThresholds;
    }
  },
  { deep: true, immediate: true }
);


const loadVitalsThresholdConfig = async () => {
  try {
    const thresholdConfigRes = await props.patientVitalConfigLoader(props.patientId, VITAL.SPO2);
    if (thresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      fetchVitalsConfigurationData.value = thresholdConfigRes.getVitalsThresholdConfigForPatient;
    }
    const pulseThresholdConfigRes = await props.patientVitalConfigLoader(props.patientId, VITAL.PULSE);
    if (pulseThresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      pulseFetchVitalsConfigurationData.value = pulseThresholdConfigRes.getVitalsThresholdConfigForPatient;
    }
  } catch (error) {
    const { message } = error as Error;
    showLoadThresholdError.value = true;
    errorMessage.value = message;
  }
}

const initializeVitalsData = (initialSPO2DataPoints: IGraphDataPoint[], initializePIDataPoints: IGraphDataPoint[], initializeHrDataPoints: IGraphDataPoint[]) => {
  if (initialSPO2DataPoints && initialSPO2DataPoints.length > 0) {
    spo2DataValue.value = first(initialSPO2DataPoints)!.data.map(patientData => last(patientData)!);
    piDataValue.value = first(initializePIDataPoints)!.data.map(patientData => last(patientData)!)
    hrDataValue.value = first(initializeHrDataPoints)!.data.map(patientData => last(patientData)!);
    return;
  }
  spo2DataValue.value = [];
  piDataValue.value = [];
  hrDataValue.value = [];
};

watch(() => props.spO2DataPoints, (updatedSPO2DataPoints) => {
  if (updatedSPO2DataPoints && !isEmpty(updatedSPO2DataPoints)) {
    spo2DataValue.value = first(updatedSPO2DataPoints)!.data.map(patientData => last(patientData)!);
  }
}, { deep: true });

watch(() => props.piDataPoints, (updatedPIDataPoints) => {
  if (updatedPIDataPoints && !isEmpty(updatedPIDataPoints)) {
    piDataValue.value = first(updatedPIDataPoints)!.data.map(patientData => last(patientData)!);
  }
}, { deep: true });

watch(() => props.hrDataPoints, (updatedHRDataPoints) => {
  if (updatedHRDataPoints && !isEmpty(updatedHRDataPoints)) {
    hrDataValue.value = first(updatedHRDataPoints)!.data.map(patientData => last(patientData)!);
  }
}, { deep: true });

const spo2DynmaicMarkers = computed(() => {

  if (isEmpty(spo2DataValue.value)) {
    return [];
  }
  const spo2Range = spo2ThresholdData.value
  const spo2Markers = spo2DataValue.value.flatMap((spo2Data, index) => {
    if (spo2Range.critical <= spo2Data && spo2Data < spo2Range.warningMin) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#B3261E', strokeColor: "#FFF", size: 6, }];

    }
    if (spo2Range.warningMin <= spo2Data && spo2Data < spo2Range.warningMax) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#FB8C00', strokeColor: "#FFF", size: 6, }];

    }
    if (spo2Range.warningMax <= spo2Data && spo2Data <= spo2Range.normal) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#4CAF50', strokeColor: "#FFF", size: 6, }];
    }
    return []
  })
  return spo2Markers;

})

const piDynamicMarkers = computed(() => {
  if (isEmpty(piDataValue.value)) {
    return [];
  }
  const spo2Range = spo2ThresholdData.value
  const piMarkers = piDataValue.value.flatMap((piData, index) => {
    if (spo2Range.critical <= piData && piData < spo2Range.warningMin) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#00ff00', strokeColor: "#FFF", size: 6, }];
    }
    if (spo2Range.warningMin <= piData && piData < spo2Range.warningMax) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#00ff00', strokeColor: "#FFF", size: 6, }];
    }
    if (spo2Range.warningMax <= piData && piData <= spo2Range.normal) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#00ff00', strokeColor: "#FFF", size: 6, }];
    }
    return []
  })
  return piMarkers;

})

const hrDynamicMarkers = computed(() => {
  if (isEmpty(hrDataValue.value)) {
    return [];
  }
  const pulseRange = pulsethreshold.value
  const pulseMarkers = hrDataValue.value.flatMap((pulseData, index) => {

    if (
      (pulseRange.criticalMin <= pulseData && pulseData < pulseRange.warnMin) ||
      (pulseRange.warnMax < pulseData && pulseData <= pulseRange.criticalMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#B3261E', strokeColor: "#FFF", size: 6, }];
    }
    if (
      (pulseRange.warnMin <= pulseData && pulseData < pulseRange.normalMin) ||
      (pulseRange.normalMax < pulseData && pulseData <= pulseRange.warnMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#FB8C00', strokeColor: "#FFF", size: 6, }];
    }
    if (pulseRange.normalMin <= pulseData && pulseData <= pulseRange.normalMax) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#4CAF50', strokeColor: "#FFF", size: 6, },];
    }
    return [];
  })
  return pulseMarkers
})
const spO2ChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "spo2-pi-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
  },
  markers: {
    size: 6,
    discrete: []
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },

} as ApexChart & { markers: { discrete: object[] } });

const piChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "spo2-pi-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },

  },
  markers: {
    size: 6,
    discrete: []
  },
  colors: ['#00ff00'],
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },

} as ApexChart & { markers: { discrete: object[] } });

const hrChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "spo2-pi-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },
  markers: {
    size: 6,
    discrete: []
  },
  colors: ['#F75E38'],
} as ApexChart & { markers: { discrete: object[] } });


watch(() => [spo2DynmaicMarkers.value], ([updatedSpo2DynamicMarkers]) => {
  const markers = [...updatedSpo2DynamicMarkers]
  spO2ChartOptions.value.markers.discrete = markers
  if (spO2Chart.value) {
    spO2Chart.value.updateOptions({
      markers: {
        discrete: [...markers],
      }
    })
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });
  const sortedSPO2DataPoints = sortBy(first(props.spO2DataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());
  const lastSPO2Index = findIndex(first(props.spO2DataPoints)!.data, (point) => first(point) === first(last(sortedSPO2DataPoints))!);
  const lastSpo2Marker = find(updatedSpo2DynamicMarkers, { dataPointIndex: lastSPO2Index });
  if (lastSpo2Marker?.fillColor) {
    lastSpo2Color.value = lastSpo2Marker.fillColor
  }
  emit('spo2ColorUpdate', { spo2: lastSpo2Color.value });
});

watch(() => [piDynamicMarkers.value], ([updatedPIDynamicMarkers]) => {
  const markers = [...updatedPIDynamicMarkers]
  piChartOptions.value.markers.discrete = markers
  if (piChart.value) {
    piChart.value.updateOptions({
      markers: {
        discrete: [...markers],
      }
    })
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });

  const sortedPIDataPoints = sortBy(first(props.spO2DataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());
  const lastPIIndex = findIndex(first(props.spO2DataPoints)!.data, (point) => first(point) === first(last(sortedPIDataPoints))!);
  const lastPI2Marker = find(updatedPIDynamicMarkers, { dataPointIndex: lastPIIndex });
  if (lastPI2Marker?.fillColor) {
    lastPiColor.value = lastPI2Marker.fillColor
  }
  emit('piColorUpdate', { pi: lastPiColor.value });

});

watch(() => [hrDynamicMarkers.value], ([updatedHRDynamicMarkers]) => {
  const markers = [...updatedHRDynamicMarkers]
  hrChartOptions.value.markers.discrete = markers
  if (hrChart.value) {
    hrChart.value.updateOptions({
      markers: {
        discrete: [...markers],
      }
    })
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });

  const sortedHRDataPoints = sortBy(first(props.hrDataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());
  const lastHRIndex = findIndex(first(props.spO2DataPoints)!.data, (point) => first(point) === first(last(sortedHRDataPoints))!);
  const lastHR2Marker = find(updatedHRDynamicMarkers, { dataPointIndex: lastHRIndex });
  if (lastHR2Marker?.fillColor) {
    lastHRColor.value = lastHR2Marker.fillColor
  }
  emit('hrColorUpdate', { hr: lastHRColor.value });

});

onMounted(async () => {
  await loadVitalsThresholdConfig()
  initializeVitalsData(props.spO2DataPoints, props.piDataPoints, props.hrDataPoints);
})
</script>

<style lang="scss" scoped>
#chart {
  margin-right: 15px;
}
</style>
