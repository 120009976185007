<template>
  <div class="w-100 my-2 px-8 py-4">
    <template v-if="isEmpty(sourceList)">
      <div class="d-flex flex-column align-center justify-center">
        <label class="text-h6 font-weight-bold">No data available</label>
        <img src="@/assets/empty.png" alt="No data available" class="no-data-available" />
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-card flat class="w-100 h-100 mx-2 text-primary " variant="tonal">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Last Reading </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2" />
              <div v-if="!isVitalDataEmpty">
                <v-row>

                  <v-col v-if="lastSPO2Reading">
                    <v-skeleton-loader v-if="isEmpty(spo2Color)" color="surface" type="text" height="60px"></v-skeleton-loader>
                    <h3 v-if="!isEmpty(spo2Color)">
                      <div class="text-caption font-weight-black">SPO2</div>
                      <span class="text-h3 font-weight-black my-4" :style="{ color: spo2Color }">{{lastSPO2Reading }}</span>
                    </h3>
                  </v-col>
                  <v-col v-if="lastPIReading">

                    <h3  >
                      <div class="text-caption font-weight-black">Perfusion Index</div>
                      <span class="text-h3 font-weight-black my-4" >{{ lastPIReading }}</span>
                    </h3>
                  </v-col>
                  <v-col v-if="lastHBReading">
                    <v-skeleton-loader v-if="isEmpty(hrColor)" color="surface" type="text" height="60px"></v-skeleton-loader>
                    <h3  v-if="!isEmpty(hrColor)">
                      <div class="text-caption font-weight-black">Heart Rate</div>
                      <span class="text-h3 font-weight-black my-4" :style="{ color:hrColor }">{{lastHBReading }}</span>
                    </h3>
                  </v-col>
                </v-row>
                <v-divider thickness="1" class="my-2" />
                <span class="text-body-1 font-weight-black no-wrap-text"><strong>{{ lastReadTime }}</strong></span>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex align-start justify-center">
          <v-card flat class="w-100 h-100 mx-2 text-primary" variant="tonal">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Reading Average </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2" />
              <div v-if="!isVitalDataEmpty">
                <v-row>
                  <v-col v-if="spO2Avg">
                    <h3>
                      <div class="text-caption font-weight-black">SPO2</div>
                      <span class="text-h3 font-weight-black my-4">{{ spO2Avg }}</span>
                    </h3>
                  </v-col>
                  <v-col v-if="piAvg">
                    <h3>
                      <div class="text-caption font-weight-black">Perfusion Index</div>
                      <span class="text-h3 font-weight-black my-4">{{ piAvg }}</span>
                    </h3>
                  </v-col>
                  <v-col v-if="hbAvg">
                    <h3>
                      <div class="text-caption font-weight-black">Heart Rate</div>
                      <span class="text-h3 font-weight-black my-4">{{ hbAvg }}</span>
                    </h3>
                  </v-col>
                </v-row>
                <v-divider thickness="1" class="my-2" />
                <span class="text-body-1 text-primary font-weight-black no-wrap-text"><strong>{{
                  lookBackTitle(selectedLookBackRange) }} Average </strong></span>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat variant="tonal" class="w-100 h-100 mx-2 text-primary">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Variation </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2 mb-4" />
              <div v-if="!isVitalDataEmpty">
                <v-row>
                  <v-col cols="6" class="d-flex my-2 justify-center">
                    <v-chip size="x-large">
                      <h5 class="text-h5 font-weight-black no-wrap-text mx-2">SPO2</h5>
                      <v-icon class="text-h4 font-weight-black" :icon=getTrendIcon(spO2Trend) />
                    </v-chip>
                    <v-tooltip activator="parent" location="top">SPO2 {{ spO2Trend }} by {{ spO2Perc }}% from {{
                      lookBackShort(selectedLookBackRange) }} average ({{ spO2Avg }}) </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="d-flex my-2 justify-center">
                    <v-chip size="x-large">
                      <h5 class="text-h5 font-weight-black no-wrap-text mx-2">Perf Index</h5>
                      <v-icon class="text-h4 font-weight-black" :icon=getTrendIcon(piTrend) />
                    </v-chip>
                    <v-tooltip activator="parent" location="top">Perfusion Index{{ piTrend }} by {{ piPerc }}% from {{
                      lookBackShort(selectedLookBackRange) }} average ({{ piAvg }})</v-tooltip>
                  </v-col>
                </v-row>
                <span class="d-flex my-2 justify-center">
                  <v-chip size="x-large">
                    <h5 class="text-h5 font-weight-black no-wrap-text mx-2">Heart Rate</h5>
                    <v-icon class="text-h4 font-weight-black" :icon=getTrendIcon(piTrend) />
                  </v-chip>
                  <v-tooltip activator="parent" location="bottom">Heart Rate {{ piTrend }} by {{ piPerc }}% from {{
                    lookBackShort(selectedLookBackRange) }} average ({{ piAvg }})</v-tooltip>
                </span>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-select v-model="selectedLookBackRange" @update:modelValue="reloadVitalData()"
            :label="lookBackRangeInput.label" variant="outlined" density="compact" :items="lookBackRangeList"
            class="pa-2"></v-select>
        </v-col>
        <v-col>
          <v-select v-model="selectedSource" :loading="loadingDataSources" @update:modelValue="reloadVitalData()"
            variant="outlined" density="compact" :items="sourceList" class="pa-2"></v-select>
        </v-col>
        <v-col cols="2" class="d-flex justify-end my-2">
          <v-btn-toggle v-model="selectedView" density="compact" elevation="1" class="my-1">
            <v-btn size="small" color="surface">
              <v-icon class="text-primary">mdi-chart-line</v-icon>
            </v-btn>
            <v-btn size="small" color="surface">
              <v-icon class="text-primary">mdi-table</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div class="w-100">
        <template
          v-if="(!enableGetPatientReadingsQuery && loadingDataSources) || (enableGetPatientReadingsQuery && loading)">
          <div class="mt-8 loading-container">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </template>
        <template v-else-if="isEmpty(sourceList)">
          <div class="w-100 text-center my-10">
            No devices associated with this patient
          </div>
        </template>
        <template v-else-if="isVitalDataEmpty">
          <div class="d-flex flex-column mt-10 align-center justify-center">
            <label class="text-h6 font-weight-bold">No data available</label>
            <img src="@/assets/empty.png" alt="No data available" class="no-data-available" />
          </div>
        </template>
        <template v-else-if="selectedView == 0">
          <PatientVitalsSPO2Graph :spO2DataPoints="patientSPO2DataPoints" :hrDataPoints="patientHRDataPoints"
            :piDataPoints="patientPIDataPoints" :patientVitalConfigLoader="patientVitalConfigLoader"
            :patientId="patientId"    @spo2ColorUpdate="handleSpo2ColorUpdate" @piColorUpdate="handlePiColorUpdate"
            @hrColorUpdate="handleHrColorUpdate" />
        </template>
        <template v-else-if="selectedView == 1">
          <PatientVitalsTable :vital-data="patientReadingTableData" :data-table-headers="patientReadingTableHeaders" />
        </template>
      </div>
    </template>
    <v-snackbar color="red" class="text-white" v-model="showFetchPatientReadingsErrMsg" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showFetchPatientReadingsErrMsg = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="showLoading" />
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch, watchEffect } from 'vue';
import { ILookBackRangeInput, IPatientDataSourceItem, ILookBackRangeItem, PatientReading, SPO2Reading, PatientReadingAvgAndTrendSPO2, IPatientSPO2TableData, VitalReading } from '@/interfaces/patient.interface';
import { onMounted } from 'vue';
import { LookBackRange, PatientVitalSPO2TableHeader, ReadingTrend, VITAL } from '@/enums/readings';
import * as momenttz from 'moment-timezone';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { useQuery } from "@tanstack/vue-query";
import { Ref } from 'vue';
import PatientVitalsSPO2Graph from "./PatientVitalSPO2Graph.vue";
import PatientVitalsTable from '../PatientVitalsTable.vue';
import { computed } from 'vue';
import { isEmpty, maxBy } from 'lodash';
import { ISource } from '@/interfaces/source.interface';
import { useEventBus } from '@vueuse/core';
import { deviceListEventBusKey } from '../../../../../events/device-list-event.bus';
import Loader from '@/components/common/Loader.vue';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';
import { IPatientVitalConfigureResponse } from '@/interfaces/IVitalConfigure';

const { patientId, dataLoader, patientDataSourceInput, lookBackRangeInput, } = defineProps({
  patientId: {
    type: String,
    required: true
  },
  dataLoader: {
    type: Function as PropType<(patientId: string, lookBackRange: string, sourceId?: string, vital?: VITAL) => Promise<PatientReading[]>>,
    required: true,
  },
  patientDataSourceInput: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<ISource[]>>,
    required: true,
  },
  lookBackRangeInput: {
    type: Object as PropType<ILookBackRangeInput>,
    required: true,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
});

const lookBackRangeList = ref([] as ILookBackRangeItem[]);
const sourceList = ref([] as IPatientDataSourceItem[])

const selectedLookBackRange = ref('ONE_MONTH');
const selectedSource = ref(null as null | string);
const selectedView = ref(0);
const showLoading = ref(false);

const loadingDataSources = ref(false);
const showFetchPatientReadingsErrMsg = ref(false);
const errorMessage = ref('');
const spO2Trend = ref('')
const spO2Perc = ref(0)
const piPerc = ref(0)
const piTrend = ref('')
const spO2Avg = ref(0)
const piAvg = ref(1)
const hbAvg = ref(2)
const lastReadTime = ref('')
const lastSPO2Reading = ref('')
const lastPIReading = ref('')
const lastHBReading = ref('')
const patientSPO2DataPoints = ref([] as IGraphDataPoint[])
const patientHRDataPoints = ref([] as IGraphDataPoint[])
const patientPIDataPoints = ref([] as IGraphDataPoint[])
const patientReadingTableData = ref([] as IPatientSPO2TableData[])
const patientReadingTableHeaders = ref(PatientVitalSPO2TableHeader)
const enableGetPatientReadingsQuery = computed(() => !!selectedSource.value);
const deviceListEventBus = useEventBus(deviceListEventBusKey);
const isVitalDataEmpty = ref(true);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const spo2Color = ref<string>('');
const piColor = ref<string>('');
const hrColor = ref<string>('');

const getTrendIcon = (trend: string) => {
  if (trend === "DOWN") {
    return 'mdi-arrow-down';
  }
  return 'mdi-arrow-up';
}

const { data: vitalData, isLoading: loading, refetch: reloadVitalData } = useQuery<PatientReading[]>({
  queryKey: ['load-bp-reading', patientId],
  enabled: enableGetPatientReadingsQuery,
  queryFn: async () => {
    showLoading.value = true;
    let patientReadings = [] as PatientReading[];
    await dataLoader(patientId, selectedLookBackRange.value, selectedSource.value as string, VITAL.SPO2).then((patientReadingsRes) => {
      patientReadings = patientReadingsRes;
      isVitalDataEmpty.value = !!isEmpty(patientReadings);
      showLoading.value = false;
    }).catch(() => {
      showFetchPatientReadingsErrMsg.value = true;
      errorMessage.value = "Error in fetching patient readings";
    });
    return patientReadings;
  },
});

watch(vitalData as Ref<PatientReading[]>, async (updatedVitalData: PatientReading[]) => {
  if (updatedVitalData) {
    loadVitalData(updatedVitalData);
  }
});


const loadVitalData = (patientVitalData: PatientReading[]) => {
  patientVitalData.forEach((patientReading) => {

    const patientSPO2Readings = patientReading.dataPoints;

    if (patientSPO2Readings) {
      const allSPO2Readings = [] as number[];
      const allPIReadings = [] as number[];
      const allHRReadings = [] as number[];
      const spO2GraphDataPoint = [] as [number, number][];
      const piGraphDataPoint = [] as [number, number][];
      const hrGraphDataPoint = [] as [number, number][];
      patientReadingTableData.value = [];

      patientSPO2Readings.forEach((patientSPO2Reading: VitalReading) => {
        const { perfusionIndex, spO2, heartRate } = patientSPO2Reading.reading as SPO2Reading;
        const { collectedTimestamp } = patientSPO2Reading;
        allSPO2Readings.push(spO2);
        allPIReadings.push(perfusionIndex);
        allHRReadings.push(heartRate);
        spO2GraphDataPoint.push([Number(collectedTimestamp), spO2]);
        piGraphDataPoint.push([Number(collectedTimestamp), perfusionIndex]);
        hrGraphDataPoint.push([Number(collectedTimestamp), heartRate]);
        patientReadingTableData.value.push(getFormattedSPO2VitalData(patientSPO2Reading))
      });

      patientSPO2DataPoints.value = [{
        name: 'SPO2',
        data: spO2GraphDataPoint
      }],

        patientPIDataPoints.value = [{
          name: 'Perfusion Index',
          data: piGraphDataPoint
        }],

        patientHRDataPoints.value = [{
          name: 'Heart Rate',
          data: hrGraphDataPoint
        }]

      const latestReading = maxBy(patientSPO2Readings, 'collectedTimestamp') as VitalReading;

      const { collectedTimeZone, collectedTimestamp } = latestReading;

      const reading = latestReading.reading as SPO2Reading;
      watchEffect(() => {
        showReadingTrend({
          pi: calculateAvgTrend(allPIReadings), spO2: calculateAvgTrend(allSPO2Readings), hb: (calculateAvgTrend(allHRReadings)),
          latestReading: {
            dateTime: collectedTimestamp,
            reading: reading,
            timeZone: collectedTimeZone
          }
        });
      });
    }
  });
}

const getFormattedSPO2VitalData = (dataPoint: VitalReading) => {
  const { reading, collectedTimestamp } = dataPoint as { reading: SPO2Reading; collectedTimestamp: string };
  const formattedDateObject = momenttz.unix(Number(collectedTimestamp) / 1000);
  const formattedDate = formattedDateObject.format('Do MMM YYYY');
  const formattedTime = formattedDateObject.format('hh:mm:ss A');

  return {
    date: formattedDate,
    time: formattedTime,
    spO2: reading.spO2,
    pi: reading.perfusionIndex,
    hr: reading.heartRate,
    details: reading.rawData.note ? reading.rawData.note : 'N/A'
  }
}

const showReadingTrend = (readingAvg: PatientReadingAvgAndTrendSPO2) => {
  spO2Trend.value = ReadingTrend[readingAvg.spO2.trend];
  piTrend.value = ReadingTrend[readingAvg.pi.trend];
  spO2Perc.value = Math.round((readingAvg.spO2.percentage < 0 ? -readingAvg.spO2.percentage : readingAvg.spO2.percentage) * 100) / 100;
  piPerc.value = Math.round((readingAvg.pi.percentage < 0 ? -readingAvg.pi.percentage : readingAvg.pi.percentage) * 100) / 100;
  spO2Avg.value = Math.round(parseFloat(readingAvg.spO2.average.toFixed(1)));
  piAvg.value = Math.round(parseFloat(readingAvg.pi.average.toFixed(1)));
  hbAvg.value = Math.round(parseFloat(readingAvg.hb.average.toFixed(1)));
  const formattedDateObject = momenttz.tz(Number(readingAvg.latestReading.dateTime), readingAvg.latestReading.timeZone);
  lastReadTime.value = formattedDateObject.fromNow();
  lastSPO2Reading.value = readingAvg.latestReading.reading.spO2 as unknown as string;
  lastHBReading.value = readingAvg.latestReading.reading.heartRate as unknown as string;
  lastPIReading.value = readingAvg.latestReading.reading.perfusionIndex as unknown as string;
}

const calculateAvgTrend = (readings: number[]) => {
  if (readings.length === 0) {
    return { trend: ReadingTrend.NONE, average: 0, percentage: 0 };
  }

  const average = readings.reduce((sum, reading) => sum + reading, 0) / readings.length;
  const trendIndex =
    readings[0] > average
      ? ReadingTrend.UP
      : readings[0] < average
        ? ReadingTrend.DOWN
        : ReadingTrend.NONE;

  const percentageDifference = ((readings[0] - average) / average) * 100;

  return { trend: trendIndex, average, percentage: percentageDifference };
};

const lookBackShort = (lbRange: string) => {
  return LookBackRange.find(range => (range.value === lbRange))?.short;
}

const lookBackTitle = (lbRange: string) => {
  return LookBackRange.find(range => (range.value === lbRange))?.title;
}

const handleSpo2ColorUpdate=((event: Record<string, string>)=> {
  spo2Color.value = event.spo2;
})
const handlePiColorUpdate=((event: Record<string, string>)=> {
  piColor.value = event.pi;
})
const handleHrColorUpdate=((event: Record<string, string>)=> {
  hrColor.value = event.hr;
})

const loadDataSources = async () => {
  loadingDataSources.value = true;
  const sources = await patientDataSourceInput(patientId, VITAL.SPO2);
  if (!isEmpty(sources)) {
    sourceList.value = sources.map((source) => {
      return {
        title: source.sourceMeta?.name as string,
        value: source.sourceId,
        label: source.sourceMeta?.name as string,
      };
    });
    selectedSource.value = sourceList.value[0].value;
  }
  loadingDataSources.value = false;
}

onMounted(async () => {
  if (vitalData.value) {
    loadVitalData(vitalData.value);
  }
  await loadDataSources();
  lookBackRangeInput.dataLoader().then((lookBackRanges) => {
    lookBackRangeList.value = lookBackRanges;
  });
  deviceListEventBus.on(loadDataSources);

  patientProgramInsightsEvent.on((response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.patientId === patientId) {
      reloadVitalData();
    }
  })
});

</script>

<style lang="scss" scoped>
.no-wrap-text {
  white-space: nowrap;
}

.loading-container {
  min-height: 40px;
  text-align: center;
}

.no-data-available {
  width: 10%;
  height: auto;
}
</style>
