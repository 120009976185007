<template>
  <v-card  :class="isSnapshot ? 'bg-white' : 'grap-card bg-surface'" flat>
    <div id="chart">
      <div class="ml-5 text-subtitle-2 font-weight-bold">SYS/DIA&nbsp;<span class="font-weight-light">(mmHg)</span>
      </div>
      <div>
        <v-skeleton-loader v-if="isEmpty(diaDynamicMarkers) " color="surface" type="image"></v-skeleton-loader>
        <VueApexCharts v-if="isChartVisible && !isEmpty(diaDynamicMarkers)  " ref="bpChart" type="line" height="350" class="mr-8 ml-1"
           :options="bpChartOptions" :series="bpDataPoints"></VueApexCharts>
      </div>
      <div class="ml-5 text-subtitle-2 font-weight-bold">HR&nbsp;<span class="font-weight-light">(bpm)</span></div>
      <div>
        <v-skeleton-loader v-if="isEmpty(hrDynamicMarkers)" color="surface" type="image"></v-skeleton-loader>
        <VueApexCharts v-if="isChartVisible && !isEmpty(hrDynamicMarkers)" ref="hrChart" type="line" height="150px" class="mr-8 ml-3"
           :options="hrChartOptions" :series="hrDataPoints"></VueApexCharts>
      </div>
    </div>
    <v-snackbar color="error" class="text-white mt-16" v-model="showLoadThresholdError" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadThresholdError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import _, { find, findIndex, first, isEmpty, last, sortBy } from 'lodash';
import VueApexCharts from "vue3-apexcharts";
import { computed, nextTick, PropType } from 'vue';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { ref, watch, onMounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { BPVitalThresholdCriteria, IPatientVitalConfigureResponse, IVitalConfigure } from '@/interfaces/IVitalConfigure'
import ApexCharts from 'apexcharts';
import { VITAL } from '@/enums/readings';
import { IPatientBPTableData } from '@/interfaces/patient.interface';


const props = defineProps({
  bpDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  hrDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true

  },
  ihbValue: {
    type: Object as PropType<IPatientBPTableData[]>,
    required: true,
  },
  isSnapshot: {
    type: Boolean,
    required: false,
    default: false
  }
})

const fetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const sysDataValue = ref<number[]>([]);
const diaDataValue = ref<number[]>([]);
const hrDataValue = ref<number[]>([])
const ihbValueType = ref<string[]>([])
const bpChart = ref<ApexCharts | null>(null);
const lastSysColor = ref<string>('');
const lastDiaColor = ref<string>('');
const emit = defineEmits(['colorUpdated', 'isGraphMounted']);
const showLoadThresholdError = ref(false);
const errorMessage = ref("");
const hrChart = ref<ApexCharts | null>(null);
const isChartVisible = ref(true);
const bpThresholdData = ref({
  systolicThreshold: {
    criticalMax: 0,
    criticalMin: 0,
    warnMin: 0,
    warnMax: 0,
    normalMin: 0,
    normalMax: 0
  },
  diastolicThreshold: {
    criticalMax: 0,
    criticalMin: 0,
    warnMin: 0,
    warnMax: 0,
    normalMin: 0,
    normalMax: 0
  }
});

watch(fetchVitalsConfigurationData, (updatedFetchVitalsConfigurationData) => {
  const systolicThresholds = (updatedFetchVitalsConfigurationData?.config as BPVitalThresholdCriteria)?.systolic.thresholds;
  const diastolicThresholds = (updatedFetchVitalsConfigurationData?.config as BPVitalThresholdCriteria)?.diastolic.thresholds;

  if (systolicThresholds) {
    bpThresholdData.value.systolicThreshold = (systolicThresholds);
  }
  if (diastolicThresholds) {
    bpThresholdData.value.diastolicThreshold = (diastolicThresholds);
  }
}, { deep: true, immediate: true })

const loadVitalsThresholdConfig = async () => {
  try {
    const thresholdConfigRes = await props.patientVitalConfigLoader(props.patientId, VITAL.BP);
    if (thresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      fetchVitalsConfigurationData.value = thresholdConfigRes.getVitalsThresholdConfigForPatient;
    }
  } catch (error) {
    const { message } = error as Error;
    showLoadThresholdError.value = true;
    errorMessage.value = message;
  }
}

const initializeVitalsData = (initialBpDataPoints: IGraphDataPoint[]) => {
  if (initialBpDataPoints && initialBpDataPoints.length > 0) {
    sysDataValue.value = first(initialBpDataPoints)!.data.map(patientData => last(patientData)!);
    diaDataValue.value = last(initialBpDataPoints)!.data.map(patientData => last(patientData)!);
    return;
  }
  sysDataValue.value = [];
  diaDataValue.value = [];
};

const initializeHrData = (initialHrDataPoints: IGraphDataPoint[], initialIHBData: IPatientBPTableData[]) => {
  if (initialHrDataPoints && initialHrDataPoints.length > 0 && initialIHBData) {
    hrDataValue.value = first(initialHrDataPoints)!.data.map(patientData => last(patientData)!)
    ihbValueType.value = props.ihbValue.map(patientIHB => (patientIHB.ihb))
  } else {
    hrDataValue.value = [];
    ihbValueType.value = []
  }
}

watch(() => props.bpDataPoints, (updatedBpDataPoints) => {
  if (updatedBpDataPoints && !isEmpty(updatedBpDataPoints)) {
    sysDataValue.value = first(updatedBpDataPoints)!.data.map(patientData => last(patientData)!);
    diaDataValue.value = last(updatedBpDataPoints)!.data.map(patientData => last(patientData)!);
  }
}, { deep: true });

watch(() => props.hrDataPoints, (updatedHrDataPoints) => {
  if (updatedHrDataPoints && !isEmpty(updatedHrDataPoints)) {
    hrDataValue.value = first(updatedHrDataPoints)!.data.map(patientData => last(patientData)!)
  }
}, { deep: true })

watch(() => props.ihbValue, (updatedIhbDataType) => {
  if (updatedIhbDataType && !isEmpty(updatedIhbDataType)) {
    ihbValueType.value = props.ihbValue.map(patientIHB => patientIHB.ihb)
  }
}, { deep: true })

const sysDynamicMarkers = computed(() => {
  if (isEmpty(sysDataValue.value)) {
    return [];
  }
  const systolicRange = bpThresholdData.value.systolicThreshold
  const sysMarkers = sysDataValue.value.flatMap((sysData, index) => {
    if (
      (systolicRange.criticalMin <= sysData && sysData < systolicRange.warnMin) ||
      (systolicRange.warnMax < sysData && sysData <= systolicRange.criticalMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#B3261E', strokeColor: "#FFF", size: 6, }];
    }
    else if (
      (systolicRange.warnMin <= sysData && sysData < systolicRange.normalMin) ||
      (systolicRange.normalMax < sysData && sysData <= systolicRange.warnMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#FB8C00', strokeColor: "#FFF", size: 6, }];
    }
    else if (systolicRange.normalMin <= sysData && sysData <= systolicRange.normalMax) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#4CAF50', strokeColor: "#FFF", size: 6, },];
    }
    return [];
  })
  return sysMarkers

});

const diaDynamicMarkers = computed(() => {
  if (isEmpty(diaDataValue.value)) {

    return [];
  }
  const diastolicRange = bpThresholdData.value.diastolicThreshold;
  const diaMarkers = diaDataValue.value.flatMap((diaData, index) => {
    if (
      (diastolicRange.criticalMin <= diaData && diaData < diastolicRange.warnMin) ||
      (diastolicRange.warnMax < diaData && diaData <= diastolicRange.criticalMax)
    ) {
      return [{ seriesIndex: 1, dataPointIndex: index, fillColor: "#B3261E", strokeColor: "#FFF", size: 6 }];
    } else if (
      (diastolicRange.warnMin <= diaData && diaData < diastolicRange.normalMin) ||
      (diastolicRange.normalMax < diaData && diaData <= diastolicRange.warnMax)
    ) {
      return [{ seriesIndex: 1, dataPointIndex: index, fillColor: "#FB8C00", strokeColor: "#FFF", size: 6 }];
    } else if (diastolicRange.normalMin <= diaData && diaData <= diastolicRange.normalMax) {
      return [{ seriesIndex: 1, dataPointIndex: index, fillColor: "#4CAF50", strokeColor: "#FFF", size: 6 }];
    }
    return [];
  });

  return diaMarkers;
});

const hrDynamicMarkers = computed(() => {
  if (isEmpty(hrDataValue.value)) {
    return [];
  }
  const hrMarkers = hrDataValue.value.flatMap((hrData, index) => {
    if (ihbValueType.value[index] === 'Yes' && hrData) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#B3261E', strokeColor: "#FFF", size: 7 }];
    }
    return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#008FFB', strokeColor: "#FFF", size: 6 }];
  });
  return hrMarkers
})

const bpChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "bp-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
    markers: {
      size: [4, 7]
    },
    ...(props.isSnapshot && { animations: { enabled: false } })
  },
  markers: {
    size: [4, 6],
    discrete: []
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },

} as ApexChart & { markers: { discrete: object[] } });


const hrChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "bp-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
    ...(props.isSnapshot && { animations: { enabled: false } })
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },
  markers: {
    size: 6,

    discrete: []
  },
  colors: ['#FB8C00'],
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (value: number, { dataPointIndex }: { dataPointIndex: number }) => {
        const ihbtype = ihbValueType.value;
        let status;
        if (ihbtype[dataPointIndex] === 'Yes') {
          status = 'IHB: Yes';
        } else if (ihbtype[dataPointIndex] === 'No') {
          status = 'IHB: No';
        } else {
          status = 'IHB: N/A';
        }
        return `bpm: ${value}<br> ${status}`;
      }
    }
  }
} as ApexChart & { markers: { discrete: object[] } });



watch(() => [diaDynamicMarkers.value, sysDynamicMarkers.value], ([updatedDiaMarkers, updatedSysMarkers]) => {

  const markers = [...updatedDiaMarkers, ...updatedSysMarkers];
  bpChartOptions.value.markers.discrete = markers;
  if (bpChart.value) {
    bpChart.value.updateOptions({
      markers: {
        discrete: [...markers],
      }
    });
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });
  const sortedSysDataPoints = sortBy(first(props.bpDataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());
  const sortedDiaDataPoints = sortBy(last(props.bpDataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());

  const lastSysIndex = findIndex(first(props.bpDataPoints)!.data, (point) => first(point) === first(last(sortedSysDataPoints))!);
  const lastDiaIndex = findIndex(first(props.bpDataPoints)!.data, (point) => first(point) === first(last(sortedDiaDataPoints))!);

  const lastSysMarker = find(updatedSysMarkers, { dataPointIndex: lastSysIndex, });
  const lastDiaMarker = find(updatedDiaMarkers, { dataPointIndex: lastDiaIndex, });

  if (lastSysMarker?.fillColor) {
    lastSysColor.value = lastSysMarker.fillColor;
  }
  if (lastDiaMarker?.fillColor) {
    lastDiaColor.value = lastDiaMarker.fillColor;
  }
  emit('colorUpdated', { systolic: lastSysColor.value, diastolic: lastDiaColor.value });
  !isEmpty(hrDataValue.value) && emit('isGraphMounted');

})

watch(() => hrDynamicMarkers.value, (updatedHrDynamicMarker) => {
  const markers = [...updatedHrDynamicMarker]
  hrChartOptions.value.markers.discrete = markers
  if (hrChart.value) {
    hrChart.value.updateOptions({
      markers: {
        discrete: [...markers]
      }
    }, false, true)
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });
})

onMounted(async () => {
  await loadVitalsThresholdConfig()
  initializeVitalsData(props.bpDataPoints);
  initializeHrData(props.hrDataPoints, props.ihbValue)

});

</script>

<style lang="scss" scoped>
#chart {
  margin-right: 15px;
}
</style>
