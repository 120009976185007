<template>
  <v-card class="grap-card" flat color="surface">
    <div id="chart">
      <div class="ml-5 text-subtitle-2 font-weight-bold">HR&nbsp;<span class="font-weight-light">(bpm)</span>
      </div>
      <v-skeleton-loader v-if="isEmpty(hrDynamicMarkers)" color="surface" type="image"></v-skeleton-loader>
      <VueApexCharts v-if="!isEmpty(hrDynamicMarkers) && isChartVisible" ref="hrChart" type="line" height="150px" class="mr-8 ml-3" :options="hrChartOptions" :series="sortedHRDataPoints">
      </VueApexCharts>
    </div>
    <v-snackbar color="error" class="text-white mt-16" v-model="showLoadThresholdError" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadThresholdError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import VueApexCharts from "vue3-apexcharts";
import { PropType, ref, onMounted ,watch, computed, nextTick } from 'vue';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { VITAL } from '@/enums/readings';
import { IPatientVitalConfigureResponse, IVitalConfigure, PulseVitalThresholdCriteria } from "@/interfaces/IVitalConfigure";
import { find, findIndex, first, isEmpty, last, sortBy } from "lodash";


const props = defineProps({
  hrDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true

  },
})

const fetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const showLoadThresholdError = ref(false);
const errorMessage = ref("");
const pulseDataValue=ref<number[]>([])
const hrChart = ref<ApexCharts | null>(null);
const isChartVisible = ref(true);
const emit = defineEmits(['colorUpdated'])
const lastHRColor = ref<string>('');

const pulsethreshold =ref ({
  criticalMax: 0,
    criticalMin: 0,
    warnMin: 0,
    warnMax: 0,
    normalMin: 0,
    normalMax: 0
})

watch(fetchVitalsConfigurationData, (updatedFetchVitalsConfigurationData) => {
  const pulseThresholds = (updatedFetchVitalsConfigurationData?.config as PulseVitalThresholdCriteria)?.pulse.thresholds;
  if (pulseThresholds) {
    pulsethreshold.value = (pulseThresholds);
  }
}, { deep: true, immediate: true })

const loadVitalsThresholdConfig = async () => {
  try {
    const thresholdConfigRes = await props.patientVitalConfigLoader(props.patientId, VITAL.PULSE);
    if (thresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      fetchVitalsConfigurationData.value = thresholdConfigRes.getVitalsThresholdConfigForPatient;
    }
  } catch (error) {
    const { message } = error as Error;
    showLoadThresholdError.value = true;
    errorMessage.value = message;
  }
}

const initializeHrData = (initialHrDataPoints: IGraphDataPoint[]) => {
  if (initialHrDataPoints && initialHrDataPoints.length > 0 ) {
    pulseDataValue.value = first(initialHrDataPoints)!.data.map(patientData => last(patientData)!)
  }else{
    pulseDataValue.value = [];
  }

}

watch(() => props.hrDataPoints, (updatedHrDataPoints) => {
  if (updatedHrDataPoints && !isEmpty(updatedHrDataPoints)) {
    pulseDataValue.value = first(updatedHrDataPoints)!.data.map(patientData => last(patientData)!)
  }
}, { deep: true })

const hrDynamicMarkers = computed(() => {
  if (isEmpty(pulseDataValue.value)) {
    return [];
  }
  const pulseRange = pulsethreshold.value
  const pulseMarkers= pulseDataValue.value.flatMap((pulseData, index)=>{

    if (
      (pulseRange.criticalMin <= pulseData && pulseData < pulseRange.warnMin) ||
      (pulseRange.warnMax < pulseData && pulseData <= pulseRange.criticalMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#B3261E', strokeColor: "#FFF", size: 6, }];
    }
    if (
      (pulseRange.warnMin <= pulseData && pulseData < pulseRange.normalMin) ||
      (pulseRange.normalMax < pulseData && pulseData <= pulseRange.warnMax)
    ) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#FB8C00', strokeColor: "#FFF", size: 6, }];
    }
    if (pulseRange.normalMin <= pulseData && pulseData <= pulseRange.normalMax) {
      return [{ seriesIndex: 0, dataPointIndex: index, fillColor: '#4CAF50', strokeColor: "#FFF", size: 6, },];
    }
    return [];
  })
  return pulseMarkers
})

const sortedHRDataPoints = computed(() => {
  return props.hrDataPoints.map(dataPoint => ({
    ...dataPoint,
    data: dataPoint.data.sort((readingA, readingB) => {
      const readingAEpoch = readingA[0];
      const readingBEpoch = readingB[0];
      return readingAEpoch - readingBEpoch;
    })
  }));
});

const hrChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),

    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },
  markers: {
    size: [4, 6],
    discrete:[]
  },
  colors: ['#F75E38'],
} as ApexChart & { markers: { discrete: object[] } });


watch(()=>hrDynamicMarkers.value,(updatedHrDynamicMarkers)=>{
  const markers=[...updatedHrDynamicMarkers];
  hrChartOptions.value.markers.discrete=markers;
  if (hrChart.value) {
    hrChart.value.updateOptions({
      markers: {
        discrete: [...markers],
      }
    });
  }
  isChartVisible.value = false;
  nextTick(() => {
    isChartVisible.value = true;
  });

  const sortedHRDataPoints = sortBy(first(props.hrDataPoints)!.data, (dataPoint) => new Date(first(dataPoint)!).getTime());
  const lastHRIndex = findIndex(first(props.hrDataPoints)!.data, (point) => first(point) === first(last(sortedHRDataPoints))!);
  const lastHRMarker = find(updatedHrDynamicMarkers, { dataPointIndex: lastHRIndex });
  if (lastHRMarker?.fillColor) {
    lastHRColor.value = lastHRMarker.fillColor
  }
  emit('colorUpdated', { pulse: lastHRColor.value });
} )

onMounted(async () => {
  await loadVitalsThresholdConfig()
  initializeHrData(props.hrDataPoints);
});

</script>

<style lang="scss" scoped>
#chart {
  margin-right: 15px;
}
</style>
